export default function Contact() {
  return (
    <div id="contact" className="contact">
      <div className="text">
        <h1>contact.</h1>
        <div className="contact-info">
          <a href = "mailto: devantespaine@gmail.com">
            <i className="fas fa-envelope"></i>
            <p>devantespaine@gmail.com</p>
          </a>
        </div>
        <a href="https://www.linkedin.com/in/devante-spaine/" target="_blank" rel="noreferrer noopener">
          <i className="fab fa-linkedin-in"></i>
          <p>linkedin.com/in/devante-spaine/</p>
        </a>
        <a href="https://github.com/dee912" target="_blank" rel="noreferrer noopener">
          <i className="fab fa-github"></i>
          <p>github.com/dee912</p>
        </a>
      </div>
      <footer>
        <p>&copy; Devante Spaine</p>
      </footer>
    </div>
  )
}