export default function Projects() {
  return (
    <div id="projects" className="projects">
      <div className="head">
        <h1>projects.</h1>
        <div className="slide">
          <img className="iMac" src="https://i.imgur.com/6bQhARN.png" alt="projects"/>
          <div className="project-carousel">
            <div id="project-image" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#project-image" data-slide-to="0" className="active"></li>
                <li data-target="#project-image" data-slide-to="1"></li>
                <li data-target="#project-image" data-slide-to="2"></li>
                <li data-target="#project-image" data-slide-to="3"></li>
              </ol>
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <img className="d-block image" src="https://i.imgur.com/KazZoY5.jpg" alt="first slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block image" src="https://i.imgur.com/ZE9s2D2.png" alt="second slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block image" src="https://i.imgur.com/aHsKqgv.jpg" alt="third slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block image" src="https://i.imgur.com/eRtjYkg.jpg" alt="fourth slide" />
                </div>
                <a className="carousel-control-prev" href="#project-image" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#project-image" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="briefs">
        <div className="project-brief">
          <div className="project-brief-title">
            <div className="git">
              <h2>PAC-MAN</h2>
              <a href="https://github.com/dee912/project-1" target="_blank" rel="noreferrer noopener"><i className="i fab fa-github fa-2x"></i></a>
              <a href="https://dee912.github.io/project-1/" target="_blank" rel="noreferrer noopener"><i className="fas fa-link fa-2x"></i></a>
            </div>
            <h3>HTML | CSS | JavaScript</h3>
          </div>
          <div className="project-brief-info">
            <p>This assignment was to build a grid-based game. It was an individual project completed within one week. For my project I built Pac-Man in the theme of Dragon Ball Z.</p>
          </div>
        </div>
        <div className="project-brief">
          <div className="project-brief-title">
            <div className="git">
              <h2>CO2 Footprint</h2>
              <a href="https://github.com/dee912/sei-project-2" target="_blank" rel="noreferrer noopener"><i className="i fab fa-github fa-2x"></i></a>
              <a href="https://carbon-footprint-dee912.netlify.app/" target="_blank" rel="noreferrer noopener"><i className="fas fa-link fa-2x"></i></a>
            </div>
            <h3>React | Axios | SASS | Bulma | Google fonts | APIs | Netlify</h3>
          </div>
          <div className="project-brief-info">
            <p>A 36-hour mock hackathon build. The idea behind the app was based around current climate change and being conscious about our individual impact through shipping items, which saw a huge increase during 2020 and into 2021.</p>
          </div>
        </div>
        <div className="project-brief">
          <div className="project-brief-title">
            <div className="git">
              <h2>R8 MY PL8</h2>
              <a href="https://github.com/dee912/project-3-server" target="_blank" rel="noreferrer noopener"><i className="i fab fa-github fa-2x"></i></a>
              <a href="https://rate-my-pl8.netlify.app/" target="_blank" rel="noreferrer noopener"><i className="fas fa-link fa-2x"></i></a>
            </div>
            <h3>React | Node | SASS | Bulma | Mongoose | Express | Axios | Cloudinary | Heroku | Netlify</h3>
          </div>
          <div className="project-brief-info">
            <p>A 9 day group-based assignment handling both the front and backend of an application to be able to deliver a full stack app. We went for a social media idea. Users would be able to sign up for an account and upload their dishes alongside instructions and others would then be able to assign a rating to that meal.</p>
          </div>
        </div>
        <div className="project-brief">
          <div className="project-brief-title">
            <div className="git">
              <h2>Independent NW</h2>
              <a href="https://github.com/dee912/project-4-server" target="_blank" rel="noreferrer noopener"><i className="i fab fa-github fa-2x"></i></a>
              <a href="https://independent-nw.netlify.app/" target="_blank" rel="noreferrer noopener"><i className="fas fa-link fa-2x"></i></a>
            </div>
            <h3>React | Python | Django | PostgreSQL | SASS | Axios | Cloudinary | Heroku | Netlify</h3>
          </div>
          <div className="project-brief-info">
            <p>For this project I decided to work as an individual. The project was a full stack application with the backend wrote in Python and the frontend in React. My idea came from the recent boom of independent stores in the North-West of England.</p>
          </div>
        </div>
      </div>
    </div>
  )
}