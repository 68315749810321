export default function Interests() {
  return (
    <div id="interests" className="interests">
      <h1>interests.</h1>
      <div className="inner">
        <div className="info">
          <div className="name">
            <h2>mountain climbing.</h2>
            <i className="i fas fa-mountain fa-3x"></i>
          </div>
          <div className="description">
            <p>I love mountain and rock climbing and have completed the national 3 peaks challenge twice. The first time was awarded and the second a leisure activity.</p>
          </div>
        </div>
        <div className="info">
          <div className="name">
            <h2>meditation.</h2>
            <i className="i fas fa-praying-hands fa-3x"></i>
          </div>
          <div className="description">
            <p>Meditation has been important to me for the past couple of years and really helps me unwind and de-stress.</p>
          </div>
        </div>
        <div className="info">
          <div className="name">
            <h2>travelling.</h2>
            <i className="i fas fa-plane fa-3x"></i>
          </div>
          <div className="description">
            <p>I have always enjoyed experiencing new cultures around the world, this love was the reason I moved to Japan for 6 months.</p>
          </div>
        </div>
      </div>
    </div>
  )
}