export default function About() {
  return (
    <div id="about" className="about">
      <a name="about"></a>
      <div className="about-me">
        <h1>about.</h1>
        <p>Hello, my name’s Devante. I’m a Junior Developer from Liverpool and based in Manchester.
          <br />
          <br />
            I’ve always had a curiosity in how and why things work and working with Software Engineers in past employment allowed me to see how both creation and understanding went hand in hand, piquing my interest.
          <br />
          <br />
            With this new found enthusiasm I began self-studying JS but wanted something more structured, leading me to General Assembly. It was at GA that I realised how much fun coding could be, and how I could use JS, React, Python, and more to create a variety of projects ranging from games to full-stack applications. It was possible to push yourself too hard during the course, and this led me to reconnect with meditation, to relieve the pressures and challenges of the day.</p>
      </div>
      <div className="tech">
        <div className="tech-title">
          <h1>technicals</h1>
        </div>
        <div className="tech-logo">
          <h2>Front-end</h2>
          <p>HTML, CSS, CSS frameworks, SASS, JavaScript, React, Axios, REST API requests, React-testing.</p>
          <h2>Back-end</h2>
          <p>Python, Django, PostgreSQL, SQL relationships, Node.js, MongoDB, Express, Cloudinary.  </p>
          <h2>Auth</h2>
          <p>Token authentication, Bcrypt, Form validation, Mongoose validation.</p>
          <h2>Package</h2>
          <p>Npm, Yarn, Pip, Pipenv.</p>
          <h2>Deployment</h2>
          <p>Netlify, Heroku.</p>
          <h2>Version control</h2>
          <p>Git, GitHub</p>
        </div>
      </div>
    </div>
  )
}