export default function Experience() {
  return (
    <div id="experience" className="xp">
      <h1>experience.</h1>
      <div className="inner">
        <div className="info">
          <div className="name">
            <h2>General Assembly.</h2>
            <img className="i" src="https://i.imgur.com/R52uTJl.png" alt="general assembly logo"/>
          </div>
          <h3>Immersive Student</h3>
          <h3 className="dates">March 2021 - June 2021</h3>
          <div className="description">
            <p>During the bootcamp we covered multiple modules gaining a solid understanding of current industry technologies and a brief feel of what it&apos;s like to work in a solo, paired or group setting. As well as working 9 - 5, Monday - Friday, I amassed 400+ hours of homework.</p>
          </div>
        </div>
        <div className="info">
          <div className="name">
            <h2>The App Kit.</h2>
            <img className="i app" src="https://i.imgur.com/cRfJEUO.png" alt="general assembly logo"/>
          </div>
          <h3>Project Manager</h3>
          <h3 className="dates">May 2020 - March 2021</h3>
          <div className="description">
            <p>Over saw app developments from start to finish acting as the project manager for each. Client ideas I worked on ranged from a crypto lottery to a delivery servce app. Working alongside Software Engineers here allowed my to get exposure to coding before beginning the GA bootcamp.</p>
          </div>
        </div>
      </div>
    </div>
  )
}