import { BrowserRouter } from 'react-router-dom'

import About from './components/About'
import Canvas from './components/Canvas'
import Contact from './components/Contact'
import Experience from './components/Experience'
import Interests from './components/Interests'
import Nav from './components/Nav'
import Projects from './components/Projects'

function App() {
  
  return (
    <BrowserRouter>
      <Nav />
      <Canvas />
      <About />
      <div className="dot-parallax"></div>
      <Projects />
      <div className="line-parallax"></div>
      <Experience />
      <div className="tri-parallax"></div>
      <Interests />
      <div className="squ-parallax"></div>
      <Contact />
    </BrowserRouter>
  )
}

export default App
