export default function Nav() {

  return (
    <nav className="navbar navbar-expand-lg navbar-light justify-content-center">
      <button className="navbar-toggler align-center" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" data-toggle="collapse" data-target=".navbar-collapse" id="navbarText">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <a className="nav-link" href="#home"><img id="logo-nav" src="https://i.imgur.com/MrfkbO5.png" alt="home"/> <span className="sr-only"></span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#about">About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#projects">Projects</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#experience">Experience</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#interests">Interests</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}