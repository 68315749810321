import { useEffect, useRef, useState } from 'react'

export default function Canvas() {
  const canvas = useRef(null)
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const c = canvas.current.getContext('2d')
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    const mouse = {
      x: null,
      y: null,
    }

    const maxRadius = 40

    const colorArray = [
      '#71C9CE',
      '#A6E3E9',
      '#CBF1F5',
      '#E3FDFD',
      '#509094'
    ]

    window.addEventListener('mousemove', (e) => {
      mouse.x = e.x
      mouse.y = e.y
      console.log('mouse', mouse)
    })

    window.addEventListener('resize', () => {
      handleResize()
      init()
    })

    function Circle(x, y, dx, dy, radius) {
      this.x = x
      this.y = y
      this.dx = dx
      this.dy = dy
      this.radius = radius
      this.color = colorArray[Math.floor(Math.random() * colorArray.length)]
      this.minRadius = radius

      this.draw = () => {
        c.beginPath()
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
        c.shadowColor = this.color
        c.shadowBlur = 15
        c.fillStyle = this.color
        c.fill()
      }

      this.update = () => {
        if (this.x + this.radius > innerWidth || this.x - this.radius < 0) {
          this.dx = -this.dx
        }
  
        if (this.y + this.radius > innerHeight || this.y - this.radius < 0) {
          this.dy = -this.dy
        }
  
        this.x += this.dx
        this.y += this.dy

        //interactivity 

        if (
          mouse.x - this.x < 50 && 
          mouse.x - this.x > -50 &&
          mouse.y - this.y < 50 &&
          mouse.y - this.y > -50 &&
          this.radius < maxRadius
        ) {
          this.radius += 1
          this.color = 'white'
        } else if (this.radius > this.minRadius) {
          this.radius -= 1
        }

        this.draw()
      }
    }

    let circleArray = []

    function init() {
      circleArray = []

      for (let i = 0; i < 500; i++) {
        const radius = Math.random() * 3 + 1
        const x = Math.random() * (innerWidth - radius * 2) + radius
        const y = Math.random() * (innerHeight - radius * 2) + radius
        const dx = (Math.random() - 0.5) * 3
        const dy = Math.random() - 0.5
      
        circleArray.push(new Circle(x, y, dx, dy, radius))
      }
    }

    function animate() {
      requestAnimationFrame(animate)
      c.clearRect(0, 0, innerWidth, innerHeight)
    
      for (let i = 0; i < circleArray.length; i++) {
        circleArray[i].update()
      }
    }
    console.log('innerwidtyh', innerWidth)
    

    
    if (innerWidth > 616) {
      init()
      animate()
    }
    
  }, [])

  return (
    <header id="home">
      <canvas
        width={size.width}
        height={size.height}
        ref={canvas}
      />
      <div className="header-text">
        <h1>DEVANTE SPAINE</h1>
        <h2>Developer</h2>
      </div>
    </header>
  )
}